<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col class="content-header-left mb-1" cols="12" md="12">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">HR</h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb style="font-size: 0.9rem">
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="14"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item active> HR </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <div
        class="col-xl-2 col-md-3 col-sm-6"
        v-for="data in modules"
        :key="data.route"
      >
        <b-col>
          <statistic-card-vertical
            :icon="data.icon"
            :statistic="data.title"
            color="info"
            :route="data.route"
            :small="data.title == 'Employee Performance' ? true : false"
          />
        </b-col>
      </div>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol,
  BBreadcrumb,
  BBreadcrumbItem,} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

export default {
  components: {
    StatisticCardVertical,
    BRow,
    BCol,

    BBreadcrumb,
  BBreadcrumbItem,
    // InvoiceList,
  },
  data() {
    return {
      data: [
        {
          icon: "UserIcon",
          title: "Daily Working",
          route: "/hr/dailyworking",
        },
        {
          icon: "FileTextIcon",
          title: "Document",
          route: "/hr/document",
        },
        {
          icon: "PhoneIcon",
          title: "Salary",
          route: "/hr/salary",
        },
        {
          icon: "EditIcon",
          title: "Memo",
          route: "/hr/memo",
        },
        {
          icon: "UserPlusIcon",
          title: "Job Vacancy",
          route: "/hr/jobvacancy",
        },
        {
          icon: "UserIcon",
          title: "Employee",
          route: "/hr/employee",
        },
        {
          icon: "StarIcon",
          title: "Attendance",
          route: "/hr/attendance",
        },
        {
          icon: "SlackIcon",
          title: "Tree",
          route: "/crm/tree",
        },
        {
          icon: "StarIcon",
          title: "Leaves",
          route: "/hr/leaves",
        },
        {
          icon: "FileMinusIcon",
          title: "Resume",
          route: "/hr/resume",
        },
        {
          icon: "StarIcon",
          title: "Employee Performance Management",
          route: "/hr/employeeperformancemanagement",
        },
        {
          icon: "StarIcon",
          title: "Asset Tracker",
          route: "/hr/assettracker",
        },
        {
          icon: "StarIcon",
          title: "Register",
          route: "/hr/register",
        },
         {
          icon: "StarIcon",
          title: "Request",
          route: "/hr/hrrequest",
        },
      ],
      userData: localStorage.getItem("userData"),
      userRole: "",
      modules: "",
      sidebarData: "",
    };
  },
  created() {
    // data
    // this.$http.get('/analytics/data').then((response) => {
    //   this.data = response.data
    // })
  },
  mounted() {
    this.userRole = JSON.parse(this.userData).role;
    this.sidebarData = JSON.parse(localStorage.getItem("sidebarData"));
    this.setRoute();
  },
  methods: {
    // kFormatter
    setRoute() {
      this.sidebarData.map((item) => {
        if (item.title == "HR") {
          this.modules = item.children;
        }
      });
      this.modules.map((item) => {
        this.data.map((inner) => {
          if (inner.title == item.title) {
            if(inner.title == 'Employee Performance Management'){
              item.title='Employee Performance'
            }
            item.route = inner.route;
          }
        });
      });
    },
  },
};
</script>
